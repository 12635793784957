import React from 'react';
import { ProductCompare as Compare } from 'Common/components/product';
import { compare, order } from '~features';
import { useAction } from 'Common/hooks';
import { useSelector } from 'react-redux';
import * as GTM from 'Common/constants/gtm';

function ProductCompare() {
    const compareList = useSelector(compare.selectors.getCompareItemsDetails);
    const specDefinitions = useSelector(compare.selectors.getSpecDefinitions);
    const isLoading = useSelector(compare.selectors.getLoading);
    const onGetCompareList = useAction(compare.actions.getCompareList);
    const onRemoveFromCompareList = useAction(compare.actions.removeFromCompareList);
    const onClearCompareList = useAction(compare.actions.clearCompareList);
    const onAddItemToCart = useAction(order.actions.addItem);
    const comparedItems = useSelector(compare.selectors.getCompareItems)

    React.useEffect(() => {
        onGetCompareList();
    }, [onGetCompareList]);

    return (
        <Compare
            items={compareList}
            specs={specDefinitions}
            onRemoveFromCompareList={onRemoveFromCompareList}
            onClearCompareList={onClearCompareList}
            onAddItemToCart={onAddItemToCart}
            isLoading={isLoading}
            gtmListValue={GTM.TAGS.COMPARED_ITEM}
            comparedItems={comparedItems}
        />
    );
}

export default ProductCompare;
